import React from 'react';
//import './FeaturedMovie.css'
import './styles.css';

export default ({item}) => {

    let firstDate = new Date(item.first_air_date);//

    let genres = [];//pega o generos
    for(let i in item.genres){
        genres.push(item.genres[i].name);
    }

    let descr = item.overview;//redução do resumo dos filmes(limite de texto)
    if(descr.length > 150){
        descr = descr.substring(0, 150)  + '...';
     }

    return(

        <section className="featured" style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(https://image.tmdb.org/t/p/original${item.backdrop_path})`
        }}>
           
           <div className="featured--vertical">
                <div className="featured--horizontal">
                    <div className="featured--name">{item.original_name}</div>
                    <div className="featured--info">
                        <div className="featured--points">{item.vote_average} pontos</div>
                        <div className="featured--year">{firstDate.getFullYear()}</div>
                        <div className="featured--seasons">{item.number_of_seasons} temporada{item.number_of_season !== 1 ? 's' : ''}</div>
                    </div>
                    
                        <div className="featured--description">{item.overview}</div>
                        <div className="featured--buttons">
                            
                            <a href={`/watch/${item.id}`} className="featured--watchbutton">► Assistir</a>
                            <a href={`/list/add/${item.id}`} className="featured--mylistbutton">+ Minha Lista</a>
                        </div>
                        <div className="featured--genres">Gêneros: <strong> {genres.join(', ')} </strong></div>

                </div>
            </div>
       
        </section>
    )
}